<template>
  <div>
    <el-drawer :visible.sync="contentDrawer" :with-header="true" @close="close" :show-close="false" :size="size"
      @opened="openContart()">
      <div slot="title" class="title_stafDrawer">
        <div class="stafDrawer_top">
          <span>合同数据统计</span><span style="color: #b2aabd;margin-left: 10px;font-size: 12px;"> (
            合同数据以已发起的为基础进行统计，处于草稿状态的不算在内 )</span>
        </div>
        <span @click="close" class="back">返回</span>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据总量：</span><span style="color: #b2aabd;margin-left: 2px;font-size: 12px;"> ( 其他数量包含签署失败、拒绝签署、合同作废等数据
            )</span>
        </div>
        <div class="demo_isone">
          <div class="isone_div"><span style="font-weight:700;font-size:14px">{{ contentDrawerList.all
          }}</span>
            <br />
            <span style="font-size:14px">已创建合同数量</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ contentDrawerList.finish
          }}</span>
            <br />
            <span style="font-size:14px">签署完成数量</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ contentDrawerList.e_unfinish }}</span>
            <br />
            <span style="font-size:14px">企业待签署数量</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ contentDrawerList.u_unfinish }}</span>
            <br />
            <span style="font-size:14px">员工待签署数量</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ contentDrawerList.all_unfinish
          }}</span>
            <br />
            <span style="font-size:14px">未签署数量</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ contentDrawerList.other
          }}</span>
            <br />
            <span style="font-size:14px">其它</span>
          </div>
        </div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据占比：</span><span style="color: #b2aabd;margin-left: 2px;font-size: 12px;">
            (企业已签署和员工已签署均不包含签署完成、其他等数据，其它包含签署失败、拒绝签署、合同作废等数据)</span>
        </div>
        <div ref="contentBox" style="height: 300px;text-align:left"></div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据走势：</span><span style="color: #b2aabd;margin-left: 2px;font-size: 12px;"> (
            企业已签署和员工已签署均不包含签署完成、签署失败、拒绝签署、合同作废等数据 )</span>
        </div>
        <div
          style="text-align:left;font-size:14px;display:flex;align-items:center;margin-top:10px;justify-content:space-between">
          <div>
            <span>数据时间</span>
            <el-radio-group v-model="timeType" style="margin-left:10px" @change="typeChange">
              <!-- -->
              <el-radio-button label="本年"></el-radio-button>
              <el-radio-button label="月份"></el-radio-button>
              <el-radio-button label="近7日"></el-radio-button>
            </el-radio-group>
          </div>

          <el-input type="text" v-if="timeType === '近7日'" placeholder="近7日" disabled size="mini" style="width:100px" />
          <el-select v-if="timeType === '月份'" v-model="date_new" placeholder="请选择" size="mini" @change="changDate">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input type="text" v-if="timeType === '本年'" placeholder="本年" size="mini" disabled style="width:100px" />
        </div>
      </div>
      <div ref="contentBoxTwo" style=" height: 500px"></div>
      <div style="text-align:left;color:#aaaaaa;padding: 0 20px;font-size: 16px">
        <span v-if="timeType === '本年'">{{ date_year }}</span>
        <span v-if="timeType === '月份'">{{ date_month }}</span>
        <span v-if="timeType === '近7日'">{{ timeType }}</span>累计：签署完成 {{ finishTotal }} 份，企业已签署 {{ e_unfinishTotal }}
        份，员工已签署 {{
            u_unfinishTotal
        }}
        份，未签署 {{ all_unfinishTotal }} 份
      </div>
    </el-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import { api } from '/src/api/base';
import * as _ from 'lodash';
export const dashboardEnterpriseIndexContract = api()('/dashboard.enterprise.index.contract.json');
import * as echarts from 'echarts';
export const dashboardEnterpriseIndexContractDatas = api()('/dashboard.enterprise.index.contract.datas.json')

export default {
  data () {
    return {
      drawer: this.contentDrawer,
      size: '50%',
      timeType: '近7日',
      tempData: null,
      contentDrawerList: {},
      dateList: [
        {
          value: '01',
          label: '1月'
        },
        {
          value: '02',
          label: '2月'
        },
        {
          value: '03',
          label: '3月'
        },
        {
          value: '04',
          label: '4月'
        },
        {
          value: '05',
          label: '5月'
        },
        {
          value: '06',
          label: '6月'
        },
        {
          value: '07',
          label: '7月'
        },
        {
          value: '08',
          label: '8月'
        },
        {
          value: '09',
          label: '9月'
        },
        {
          value: '10',
          label: '10月'
        },
        {
          value: '11',
          label: '11月'
        },
        {
          value: '12',
          label: '12月'
        }
      ],
      date_new: '',
      date_month: '',
      date_year: '',
      data: null,
      dataLine: null,
      pie_titleData: [],
      u_unfinishTotal: '',
      finishTotal: '',
      e_unfinishTotal: '',
      all_unfinishTotal: ''


    }
  },
  components: {

  },
  props: {
    contentDrawer: Boolean
  },


  methods: {
    async openContart () {
      await this.loadData()
      this.date_new = moment().format('MM')
      this.date_month = moment().format('YYYY年MMM')
      this.date_year = moment().format('YYYY年')
      this.setOption();
      await this.lineDateList()
      this.setOptionLine();
    },
    async loadData () {
      const data = await dashboardEnterpriseIndexContract()
      this.contentDrawerList = data
    },
    async changDate (e) {
      this.date_month = this.date_month.slice(0, 5) + e.slice(1, 2) + '月'
      this.date_new = e
      await this.lineDateList()

    },
    async lineDateList () {
      let params = {}
      if (this.timeType === '月份') {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',
          month: moment().format('YYYY') + this.date_new
        }
      }
      else {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',

        }
      }
      const data = await dashboardEnterpriseIndexContractDatas(params)
      this.tempData = data
      console.log(data);
      this.all_unfinishTotal = _.sumBy(data.all_unfinish, it => Number(it.num))
      this.e_unfinishTotal = _.sumBy(data.e_finish, it => Number(it.num))
      this.finishTotal = _.sumBy(data.finish, it => Number(it.num))
      this.u_unfinishTotal = _.sumBy(data.u_finish, it => Number(it.num))
      this.setOptionLine()
    },
    close () {
      this.drawer = false;
      this.timeType = '近7日'
      this.$emit('close', this.drawer)
    },
    setOptionLine () {
      const list = this.tempData;
      console.log(list);
      var myChart = echarts.init(this.$refs.contentBoxTwo);
      window.onresize = function() {
    myChart.resize();
  };
      var option = {
        grid: {
          top: '18%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
          formatter: (params) => {
            return `<div style="text-align:left;padding:5px">${this.timeType === '本年' ? moment(params[0].name).format('YYYY-MM') : moment(params[0].name).format('YYYY-MM-DD')}<br/>${params.map(n => `${n.seriesName}:${n.value}个`).join('<br/>')}</div>`;
          },
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          top: '10%',
          data: ['未签署', '员工已签署', '企业已签署', '签署完成'],
          textStyle: {
            'fontSize': 14,
          },
          icon: 'rect',
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: list.all_unfinish.map(it => it.date),
        },

        yAxis: {
          name: '份数',
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        series: [
          {
            name: '未签署',
            type: 'line',
            itemStyle: {
              color: '#8400ff',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.all_unfinish.map(it => it.num),
          },

          {
            name: '员工已签署',
            type: 'line',
            itemStyle: {
              color: '#02a7f0',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.u_finish.map(it => it.num),
          },
          {
            name: '企业已签署',
            type: 'line',
            itemStyle: {
              color: '#6db103',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.e_finish.map((it) => it.num),
          },
          {
            name: '签署完成',
            type: 'line',
            itemStyle: {
              color: '#f59a23',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.finish.map((it) => it.num),
          },
        ],
      };
      myChart.setOption(option, true);
    },
    setOption () {
      console.log(this.$refs.contentBox);
      const list = this.contentDrawerList;
      console.log(list);
      this.pie_titleData = [
        { value: list.finish, name: '签署完成' },
        { value: list.u_unfinish, name: '企业已签署' },
        { value: list.e_unfinish, name: '员工已签署' },
        { value: list.all_unfinish, name: '未签署' },
        { value: list.other, name: '其它' }
      ]
      var myChart = echarts.init(this.$refs.contentBox);
      window.onresize = function() {
    myChart.resize();
  };
      var option;

      option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item',
        },

        legend: {
          orient: 'vertical',
          top: 'middle',
          formatter: name => {
            const num = this.pie_titleData.find(n => n.name === name).value
            console.log(name);
            return `${name}共计 ${num}份,占已创建合同数的${(num * 100 / (this.contentDrawerList.all || 1)).toFixed(2)}% `;
          },
          align: 'left',
          left: '50%',


        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            right: '50%',
            // top: '10%',
            // width: '60%',
            // height: '60%',
            data: this.pie_titleData,
            center: ['50%', '50%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);

    },
    async typeChange () {
      await this.lineDateList();
      this.setOptionLine();
    },


  },
  destroyed () {
    window.removeEventListener('resize', this.initEchart, 20)
  },
}
</script>
<style >
.el-drawer__header {
  text-align: left;
}

.el-radio-button__inner {
  padding: 5px 10px;
  border-radius: 0;
}
</style>
<style lang="scss" scoped>
.title_stafDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stafDrawer_top {
    span:nth-child(1) {
      font-size: 20px;
      color: #333333;
    }

    span:nth-child(2) {
      color: #b2aabd;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .back {
    color: #1989fa;
    font-size: 14px;
  }
}

.stafDrawer_demo {
  padding: 0 20px;
}

.demo_isone {
  padding: 20px;
  display: flex;
  align-items: center;

  .isone_div {
    padding: 20px 30px;
  }
}
</style>